<template>
    <div class="team-about__container" v-show="!isShowNoData">
           <h3 class="team-about__title">{{$t('ABOUT')}}</h3>
           <div v-html="aboutTeam" class="team-about__content"></div> 
    </div>
    <div class="no-data" v-show="isShowNoData">{{$t('NO_ABOUT')}}</div>
</template>

<script>
import about from '@/js/about/team.js'
import {mapGetters} from 'vuex'
export default {
    data() {
        return {
            aboutTeam : '',
            isShowNoData : false
        }
    },
    created() {
           this.teamKey = this.$route.params.teamKey;
    },
    computed: {
        ...mapGetters([
            'currentLocale'
        ]),
    },
    watch: {
        currentLocale(newValue, oldValue) {
            this.getTeamAboutData();
        }
    },
    mounted() {
        this.getTeamAboutData();
    },
    methods:{
        getTeamAboutData() {
            
            if ( typeof about[this.currentLocale] !== "undefined") {
                this.aboutTeam = about[this.currentLocale][this.teamKey];
                if (typeof this.aboutTeam === "undefined") {
                    this.isShowNoData = true;
                } else {
                    this.isShowNoData = false;
                }
            } else {
                 this.isShowNoData = true;
            }
            
        }
    }
}
</script>

<style>
    .team-about__title{
        margin-bottom: 1rem;
    }
    .team-about__container{
        width: 50%;
        box-sizing: border-box;
        padding: 0 1rem;
        font-size: .88rem;
        color: var(--color-grey-light);
        line-height: 1.3rem;
    }
      .team-about__content{
          padding-bottom: 1rem;
      }
    .team-about__content p{
        margin-bottom: 1rem;
    }
    @media (max-width: 768px){
        .team-about__title{
            font-size: .77rem;
        }
         .team-about__container{
           width: 100%;
        }
    }

</style>