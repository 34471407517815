
module.exports = {
    en : {
        '27' : `<p>Manchester United Football Team is an English Premier League football club headquartered in Old Trafford, Greater Manchester. Manchester United is one of the most popular clubs in English football history, having won 18 league crowns, four League Cups, and a record 11 FA Cups. The team has also won three European Cups and is the only one to have won the Premier League, FA Cup, and UEFA Champions League all in the same season in 1998–99.</p>
                  <p>Manchester United is one of the world's wealthiest and most popular football clubs. According to reports, the club is valued at £1.19 billion, making it the most expensive football club in the world.</p>
                  `,
        '24' : `<p>Chelsea Football Club is an accomplished English football club situated in west London. They were founded in 1905 and play in the Premier League. They have spent most of their existence in the top rank of English football. Chelsea has won the English Premier League four times, the FA Cup six times, and the League Cup four times. They've also had success in Europe, having won the UEFA Winners' Cup two times.</p>`,
        '25' : `<p>Liverpool Football Club is an English Premier League professional football club based in Liverpool, England. They are one of the most successful clubs in English football history, having won a joint record of 18 league titles, seven FA Cups, and a record seven League Cups. Liverpool is the most famous English club in the European football tournament, having won five European Cups, the most recent of which was in 2005, as well as three UEFA Cups. With the most international trophies won, the club presently stands third in Europe and sixth in the globe.</p>`,
        '82' : `<p>Real Madrid Club de Fútbol, or simply Real Madrid, is a pro football club based in Madrid, Spain. It is the most successful team in Spanish football and was named the most prolific club in the twentieth century by FIFA, having won a record 31 La Liga titles, 17 Spanish "Copa del Rey" Cups, eight Spanish Super Cups, a record nine UEFA Champions Leagues, two UEFA Cups, one UEFA Supercup, and three Intercontinental Cups. In 2008, the club was the world's richest football club in terms of income (€401 million) and the second most valued (more than €950 million).</p>`,
        '84' : `<p>Barcelona is a Spanish competitive football club headquartered in Barcelona, Spain. It is known simply as Barcelona and colloquially as Barça. With annual revenue of €366 million, FC Barcelona is the world's second-richest football club in terms of income. They have won 20 La Liga championships, a remarkable 25 Spanish Cups, nine Spanish Super Cups, and two League Cups, making them the most prominent club in Spanish football in terms of overall honors. They are also one of the world's most successful clubs, having won 14 recognized trophies, including 10 UEFA tournaments.</p> 
                   <p>Barcelona is the only European club that has competed in continental football every year since 1955. Barcelona has become the first team in Spain to win the triple of La Liga, Copa del Rey, and Champions League in 2009. In the same year, they became the first football club in history to win six consecutive competitions, completing the sextuple, which included the aforementioned treble as well as the UEFA Super Cup, Spanish Super Cup, and FIFA Club World Cup.</p>
                   `
    },
    th : {
        '27' : `<p>สโมสรฟุตบอลแมนเชสเตอร์ยูไนเต็ด เป็นสโมสรจากเมืองแมนเชสเตอร์ ประเทศอังกฤษ ปัจจุบันแข่งขันในพรีเมียร์ลีกซึ่งเป็นลีกสูงสุดของฟุตบอลอังกฤษ และเป็นทีมที่คว้าแชมป์ลีกสูงสุดอังกฤษได้มากที่สุด 20สมัย สโมสรมีฉายา "ปีศาจแดง" ก็ตั้งเมื่อปี ค.ศ 1878 มีชื่อเดิมว่านิวตันฮีตแอลวายอาร์ต่อมาเปลี่ยนชื่อเป็นแมนเชสเตอร์ยูไนเต็ดใน ค.ศ. 1902 และย้ายไปเล่นที่สนามเหย้าปัจจุบันอย่างโอลด์แทรฟฟอร์ดใน ค.ศ. 1910</p>
                  <p>แมนเชสเตอร์ยูไนเต็ด ถือว่าเป็นทีมที่ประสบความสำเร็จมากที่สุดของเกาะอังกฤษ และเป็นสโมสรฟุตบอลแรกของอังกฤษที่ชนะเลิศยูโรเปียนคัพภายใต้การคุมทีมของแมตต์ บัสบี นอกจากนี้แมนเชสเตอร์ยูไนเต็ดชนะเลิศถ้วยรางวัลมากมาย โดยชนะเลิศลีก 20 สมัย, เอฟเอคัพ 12 สมัย, ลีกคัพ 5 สมัย และเอฟเอคอมมิวนิตีชีลด์ 21 สมัย(สถิติสูงสุด), ยูโรเปียนคัพ/ยูฟ่าแชมเปียนส์ลีก 3 สมัย, ยูฟ่ายูโรปาลีก 1 สมัย, ยูฟ่าคัพวินเนอร์สคัพ 1 สมัย, ยูฟ่าซูเปอร์คัพ 1 สมัย, อินเตอร์คอนติเนนตัลคัพ 1 สมัย และฟุตบอลชิงแชมป์สโมสรโลก 1 สมัย โดยในฤดูกาล 1998–99 สโมสรกลายเป็นทีมแรกในประวัติศาสตร์ฟุตบอลอังกฤษที่คว้าทริปเปิลแชมป์ระดับทวีปยุโรป</p>
                  `,
        '24' : `<p>สโมสรฟุตบอลเชลซี สโมสรฟุตบอลอาชีพที่ตั้งอยู่ในเขตฟูลัม, ลอนดอน  โดยในตอนแรกใช้ชื่อว่า ฟูแลมเอฟซี แต่ไปซ้ำกันกับสโมสรฟุตบอลฟูแลม เลยต้องเปลี่ยนชื่อไปใช้ เคนชิงตันเอฟซี,สแตมฟอร์ดบริดจ์เอฟซี แต่สุดท้ายก็เปลี่ยนชื่อมาเป็น ลอนดอน เอฟซี และในที่สุดก็ลงเอยเปลี่ยนเป็นเชลซีเอฟซี จนถึงปัจจุบัน สโมสรเชลซีได้เลื่อนชั้นมาเล่นดิวิชั่น1ครั้งแรกในซีซั่นที่2หลังการก่อตั้งสโมสร (ฤดูกาล 1906-07) และหลังจากนั้นมา เชลซีก็ได้เล่นอยู่บนลีกสูงสุดของประเทศเป็นส่วนใหญ่  </p>
                  <p>สโมสรฟุตบอลเชลซีก่อตั้งขึ้นเมื่อวันที่ 10 มีนาคม ค.ศ. 1905 การเปลี่ยนแปลงครั้งใหญ่ของสโมสรเกิดขึ้นเมื่อปี ค.ศ 2004 หลังจาก มหาเศรษฐีชาวรัสเซีย โรมัน อับราโมวิช  ได้เข้ามา “เทคโอเวอร์” สโมสรในราคา 140 ล้านปอนด์ และได้ทุ่มซื้อสตาร์ดังมามากมาย จนทุกวันนี้เชลซีกลายเป็นหนึ่งในสโมสรที่ประสบความสำเร็จมากที่สุดของอังกฤษ โดยคว้าแชมป์จากการแข่งขันมากมาย ชนะเลิศดิวิชันหนึ่ง/พรีเมียร์ลีก 6สมัย ,ชนะเลิศลีกรองดิวิชันสอง 2 สมัย , เอฟเอคัพ 2สมัย, ลีกคัพ 5สมัย , คอมมิวนิตีชีลด์ 4สมัย, ทั้งนี้เชลซียังประสบควาสำเร็จในระดับทวีปโดยพวกเขาคว้า ยูฟ่าแชมเปียนส์ลีก 2สมัย, ยูฟ่ายูโรปาลีก 2มัย , ยูฟ่าคัพวินเนอร์สคัพ 2สมัย , ยูฟ่าซูเปอร์คัพ 2 สมัย และ ฟีฟ่าคลับเวิลด์คัพ อีก 1สมัย</p>
                  `,
        '25' : `<p>สโมสรฟุตบอลลิเวอร์พูลเป็นสโมสรฟุตบอลอาชีพตั้งอยู่ที่เมืองลิเวอร์พูล ประเทศอังกฤษ แข่งขันอยู่ในพรีเมียร์ลีก ลีกสูงสุดของของประเทศอังกฤษ ก่อตั้งโดย จอห์น โฮลดิง เมื่อปี ค.ศ. 1892 ลิเวอร์พูลใช้สนามแอนฟีลด์เป็นสนามเหย้าตั้งแต่ก่อตั้งสโมสร</p>
                  <p>ลิเวอร์พูลเป็นทีมจากอังกฤษที่ประสบความสำเร็จในเวทียูโรปหรือระดับนานาชาติมากที่สุด โดยชนะเลิศยูฟ่าแชมเปียนส์ลีก 6 สมัย, ยูฟ่าคัพ 3 สมัย, ยูฟ่าซูเปอร์คัพ 4 สมัย และฟีฟ่าคลับเวิลด์คัพ 1 สมัย ส่วนในระดับประเทศ ลิเวอร์พูลคว้าลีกสูงสุดไปแล้วทั้งสิ้น 19สมัย(เป็นรองแค่แมนเชสเตอร์ยูไนเต็ด1สมัย) ชนะเลิศลีกรองดิวิชันสอง 4 สมัย ,เอฟเอคัพ 7สมัย,ลีกคัพ 8สมัย  , คอมมิวนิตีชีลด์ 15สมัย</p>
                  <p>ลิเวอร์พูลเป็นหนึ่งในสโมสรที่มีผู้สนับสนุนมากที่สุดในโลกโดยเฉพาะประเทศไทยที่มีสาวก หงส์แดง มากเป็นอันดับต้นๆ ลิเวอร์พูลมีฉายา "เดอะเรดส์" (The Reds) ส่วนฉายาที่ชาวไทยนิยมเรียกคือ "หงส์แดง" ลิเวอร์พูลมีเพลงประจำสโมสรคือ "ยูลล์เนฟเวอร์วอล์กอะโลน" (You'll Never Walk Alone)</p>
                 `,
        '82' : `<p>สโมสรฟุตบอลเรอัลมาดริด เป็นสโมสรฟุตบอลอาชีพตั้งอยู่ใน กรุงมาดริด ประเทศสเปน และเป็นที่มีชื่อเสียงมากที่สุดอันดับต้นๆของโลก ก่อตั้งขึ้นเมื่อปี ค.ศ. 1902 มีฉายา ราชันชุดขาว เล่นในลีกสูงสุดลาลิกา สเปน และสนามเหย้าของสโมสรคือสนามซานเตียโก เบร์นาเบว</p>
                   <p>เรอัลมาดริด</p>
                   <p>เป็นสโมสรที่ประสบความสำเร็จมากที่สุดในประวัติศาสตร์รายการแข่งขันของยูฟ่าด้วยการคว้าแชมป์ยูฟ่าแชมเปียนส์ลีก 13 สมัย, ยูฟ่าคัพ 2 สมัย และ ยูฟ่า ซูเปอร์คัพ 4 สมัย ส่วนในการแข่งขันภายในประเทศ สโมสรยังประสบความสำเร็จมากมายประกอบด้วย สถิติแชมป์ลาลีกา 34 สมัย , โคปาเดลเรย์ 19 สมัย, ซูเปอร์โคปาเดเอสปาญา 11 สมัย และ แชมป์สโมสรโลก 4สมัย</p>
                   `,
        '84' : `<p>สโมสรฟุตบอลบาร์เซโลน่า หรือเรียกสั้นๆว่า บาร์ซ่า ตั้งอยู่ที่เมืองบาร์เซโลน่า แคว้นกาตาลุญญา ประเทศสเปน เล่นอยู่ในลาลิกาและเป็น 1 ใน 3 สโมสรที่ไม่เคยตกชั้นจากลีกสูงสุดของสเปน ก่อตั้งในชื่อ ฟุตบอลคลับบาร์เซโลนา ใน ค.ศ. 1899 โดยกลุ่มของนักฟุตบอลชาวสวิส, อังกฤษ และสเปน นำโดย ฌูอัน กัมเป</p>
                    <p>บาร์เซโลนาเป็นเป็นสโมสรที่ประสบความสำเร็จสูงในการแข่งขันฟุตบอลลีกของสเปนในแง่ของจำนวนถ้วยรางวัลภายในประเทศ โดยชนะเลิศการแข่งลาลิกา 26 สมัย, โกปาเดลเรย์ 31 สมัย, ซูเปร์โกปาเดเอสปัญญา 13 สมัย, โกปาเอบาดัวร์เต 3 สมัย และได้รางวัลโกปาเดลาลิกา 2 สมัย นอกจากนี้ยังเป็นสโมสรที่ประสบความสำเร็จสูงในการแข่งขันระดับทวีปของยุโรป โดยชนะเลิศรายการยูฟ่าแชมเปียนส์ลีก 5 สมัย, ยูฟ่าคัพวินเนอร์สคัพ 4 สมัย, ยูฟ่าซูเปอร์คัพ 5 สมัย และฟีฟ่าคลับเวิลด์คัพอีก 3 สมัย พวกเขายังมีสถิติชนะเลิศรายการอินเตอร์-ซิตีส์แฟส์คัพ 3 สมัย ซึ่งถือถ้วยต้นแบบของการแข่งขันยูฟ่าคัพ</p>
                    <p>ทั้งนี้บาเซโลน่า ยังมีศูนย์ฝึก ลา มาเซีย ที่ได้เพาะบ่มนักเตะเวิลด์คลาสมากมาย ขึ้นมาสู่ทีมชุดใหญ่ของบาร์ซ่า เพราะเป็นศูนย์ฝึกเดียวของโลกใบนี้ที่สามารถสร้างแคนดิเดทชิงรางวัลบัลลงดอร์ทั้ง 3 คนในปีเดียวกันได้ นั่นคือ ลิโอเนล เมสซี่, อันเดรส อิเนียสต้า และ ชาบี้ เอร์นานเดซ เมื่อปี 2010 และผู้เล่นของทีมบาร์เซโลน่าหลายต่อหลายคนยังประสบความสำเร็จทางด้านผลงานส่วนตัวซึ่งเป็นเกียรติประวัติระดับโลกมากมายเช่น ได้รับรางวัลบาลงดอร์ 12 ครั้ง</p>
                    `
    }
}
